<template>
    <div class="inner_pages">
        <!-- <InnerSidebar/> -->
        <workout-component v-if="tab == 'workouts'" :tab="tab" :switch-tab="handleSwitchTab"></workout-component>
        <plan-component v-if="tab == 'plan'" :tab="tab" :switch-tab="handleSwitchTab"></plan-component>
        <workout-response v-model="workoutResponse" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'

    // const InnerSidebar = defineAsyncComponent(() => import('@/components/InnerSidebar'))
    const WorkoutComponent = defineAsyncComponent(() => import('@/pages/workout/components/Workout'))
    const PlanComponent = defineAsyncComponent(() => import('@/pages/workout/components/Plan'))
    const WorkoutResponse = defineAsyncComponent(() => import('@/pages/workout/components/WorkoutResponse'))

    export default {
        name: "Workouts Index",

        data () {
            return{
                tab: 'workouts',
                workoutResponse: false,
            }
        },

        components: {
            // InnerSidebar,
            WorkoutComponent,
            PlanComponent,
            WorkoutResponse,
        },

        mounted () {
            const vm = this;

            if (vm.$route.query && vm.$route.query.tab) {
                vm.tab = vm.$route.query.tab;
            }

            if (vm.$route.query && vm.$route.query.response && vm.$route.query.tab !== 'plan') {
                vm.workoutResponse = true;
            }
        },

        methods: {
            handleSwitchTab (tab) {
                const vm = this;

                vm.tab = tab;

                vm.$router.push({ name: 'WorkoutIndex', query: { tab }});
            }
        }
    }
</script>

<style scoped>
    :deep(.workout-plan-beta) {
        font-weight: 600;
        text-transform: uppercase;
        color: #2F7FED;
        background: #FFF;
        padding: 3px;
        border-radius: 5px;
        letter-spacing: 1px;
        font-size: 10px;
    }
</style>